import { useCanShowFeature2 } from "hooks/can-show-feature";
import React from "react";

export type FeatureFlagWallProps = Readonly<{
  children: React.ReactNode;
  fallback?: React.ReactNode | undefined;
  loading?: React.ReactNode | undefined;
  name: string;
}>;

/**
 * 機能フラグが true のユーザーは見せる / そうでない場合は見せないを行うためのコンポーネント。
 * **XXX: あくまで描画しないだけなので、コード上は見えてしまうことに注意**
 *
 * @param param
 * @param param.name 機能フラグ名
 * @param param.children 機能フラグが true の際に見せる要素
 * @param param.loading 機能フラグの状態を取得している時に見せる要素。何も出す必要がない場合は渡さなくてよい。
 * @param param.fallback 機能フラグが false の際に見せる要素。何も出す必要がない場合は渡さなくてよい。loading を指定しなかった場合は、読み込み中も fallback が表示される。
 *
 * @returns 機能フラグに応じて返す要素
 */
export const FeatureFlagWall: React.FC<FeatureFlagWallProps> = ({
  children,
  fallback,
  loading,
  name,
}) => {
  const { canShowFeature, loading: flagLoading } = useCanShowFeature2(name);

  // 読み込み中のコンポーネントが渡されているなら、取得中はそれを表示する
  if (loading && flagLoading) return <>{loading}</>;

  return <>{canShowFeature ? children : fallback}</>;
};
