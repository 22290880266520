import { gql } from "@apollo/client";
import { useCheckCanShowFeatureQuery } from "generated/graphql";

gql`
  query CheckCanShowFeature($name: String!) {
    canShowFeature(name: $name)
  }
`;

/**
 * 機能フラグを使うためのフック。
 *
 * 機能が有効であるかどうかを表す canShowFeature と読み込み中かどうかを表す loading を返す。
 *
 * フラグの値は GraphQL APIで取得しているが、キャッシュは行っていない。
 * フラグの値をキャッシュしてしまうと、API から値を取得している間はキャッシュに残った値にもとづき要素出し分けるため、
 * 本来ユーザーに見せたい要素と異なる要素が出てしまう可能性がある。
 */
export const useCanShowFeature2 = (
  name: string
): { canShowFeature: boolean; loading: boolean } => {
  const { data, loading } = useCheckCanShowFeatureQuery({
    variables: { name },
    fetchPolicy: "no-cache",
  });

  return { canShowFeature: data?.canShowFeature ?? false, loading };
};
