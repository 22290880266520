import dayjs from "dayjs";

/**
 * コンテスト関連のタグをタグ編集で手動入力をさせないためのリスト
 *
 * // XXX: 全角カナで定義しても半角カナで回避できるので、カタカナを含む場合はどちらのパターンも用意しておく必要がある
 */
export const ContestTagsBlockList = ["マンガノ大賞", "ﾏﾝｶﾞﾉ大賞"];

/**
 * コンテンスト応募用のタグ
 */
export const ContestTag = {
  manganoAward: "第1回マンガノ大賞",
  manganoAward2nd: "第2回マンガノ大賞",
} as const;

/**
 * コンテストの応募可能な期間
 */
export const ContestApplicableTerms = {
  // TODO(mangano-award-2nd): 日程確定したら埋める
  manganoAward2nd: {
    from: dayjs("2024-11-01T00:00:00+09:00"),
    to: dayjs("2025-02-01T00:00:00+09:00"),
  },
} as const;

export const ContestIdentifiers = {
  manganoAward2nd: "mangano-award-2nd",
} as const;
